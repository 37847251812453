import { Theme } from 'lib/types'

const GAP = 8

export const colors = {
    primary: '#008266',
    background: '#ffffff',
    error: '#ff0000',
    border: '#e0e0e0',
    white: '#ffffff',
    black: '#000000',
    gray: {
        light: '#e0e0e0',
        medium: '#9DB2BF',
        strong: '#455A64'
    }
}

const zIndex = {
    level1: 1,
    level100: 100,
    level999: 999
}

export const lightTheme: Theme = {
    colors,
    zIndex,
    gap: GAP
}

export const darkTheme: Theme = {
    colors,
    zIndex,
    gap: GAP
}

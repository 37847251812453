export enum Appearance {
    Dark = 'dark',
    Light = 'light'
}

export type Theme = {
    colors: {
        primary: string
        background: string
        border: string
        error: string,
        white: string,
        black: string,
        gray: {
            light: string,
            medium: string,
            strong: string
        }
    },
    zIndex: {
        level1: number,
        level100: number,
        level999: number
    },
    gap: number
}

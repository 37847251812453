import 'react-native-reanimated'
import React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { ThemeProvider } from 'styled-components/native'
import { Provider, createStore } from 'jotai'
import * as SplashScreen from 'expo-splash-screen'
import { AppRouter } from 'lib/router'
import { useTheme } from 'lib/hooks'
import { AppLayout } from 'lib/layout'

SplashScreen.preventAutoHideAsync()

const store = createStore()

export const App: React.FunctionComponent = () => {
    const theme = useTheme()

    return (
        <NavigationContainer>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <AppLayout>
                        <AppRouter />
                    </AppLayout>
                </ThemeProvider>
            </Provider>
        </NavigationContainer>
    )
}

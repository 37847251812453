import React from 'react'
import { View } from 'react-native'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/native'
import { FormAdapter } from 'lib/components'

export const Login: React.FunctionComponent = () => {
    const { control } = useForm({
        mode: 'all'
    })

    return (
        <Container>
            <FormAdapter.InputAdapter
                control={control}
                name="login"
                label="Login"
                validationRules={{
                    required: 'login is required'
                }}
            />
            <FormAdapter.InputAdapter
                control={control}
                name="password"
                label="Password"
                isPassword
                validationRules={{
                    required: 'password is required'
                }}
            />
        </Container>
    )
}

const Container = styled(View)`
    padding: ${({ theme }) => theme.gap * 3}px;
    flex-direction: column;
    display: flex;
`

import React from 'react'
import { View, Text } from 'react-native'

export const Register: React.FunctionComponent = () => (
    <View>
        <Text>
            Register component
        </Text>
    </View>
)

import { useEffect } from 'react'
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useTheme } from 'lib/hooks'

const DURATION = 300
const HEIGHT = 18

export const useErrorAnimation = (isError: boolean) => {
    const theme = useTheme()
    const errorContainerHeight = useSharedValue(0)
    const errorAnimatedStyle = useAnimatedStyle(() => ({
        height: errorContainerHeight.value,
        overflow: 'hidden',
        marginLeft: theme.gap,
        marginBottom: isError
            ? theme.gap
            : 0
    }))

    useEffect(() => {
        if (isError) {
            errorContainerHeight.value = withTiming(HEIGHT, { duration: DURATION })

            return
        }

        errorContainerHeight.value = withTiming(0, { duration: DURATION })
    }, [isError])

    return errorAnimatedStyle
}

import React from 'react'
import { Pressable as NativePressable, PressableProps } from 'react-native'
import styled from 'styled-components/native'

export const Pressable: React.FunctionComponent<PressableProps> = ({ ...props }) => (
    <StyledPressable
        onPress={props.onPress}
        tabIndex={-1}
    >
        {props.children}
    </StyledPressable>
)

const StyledPressable = styled(NativePressable)`
    cursor: text;
`

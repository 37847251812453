import React, { PropsWithChildren, useCallback } from 'react'
import { View } from 'react-native'
import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

export const AppLayout: React.FunctionComponent<PropsWithChildren> = ({ ...props }) => {
    const [fontsLoaded] = useFonts({
        'Roboto-Regular': require('assets/fonts/Roboto-Regular.ttf')
    })

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync()
        }
    }, [fontsLoaded])

    if (!fontsLoaded) {
        return null
    }

    return (
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
            {props.children}
        </View>
    )
}

import React from 'react'
import { useController, Control, RegisterOptions } from 'react-hook-form'
import { Input } from 'lib/components'

type InputAdapterProps = {
    name: string,
    label?: string,
    control: Control,
    isPassword?: boolean,
    defaultValue?: string,
    validationRules?: RegisterOptions
}

export const InputAdapter: React.FunctionComponent<InputAdapterProps> = ({
    name,
    label,
    control,
    isPassword,
    validationRules,
    defaultValue
}) => {
    const { field, fieldState } = useController({
        name,
        control,
        defaultValue: defaultValue || '',
        rules: validationRules
    })

    return (
        <Input
            value={field.value}
            isPassword={isPassword}
            label={label}
            fieldState={fieldState}
            onBlur={field.onBlur}
            onChangeText={field.onChange}
        />
    )
}

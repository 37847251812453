import React, { useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import Animated from 'react-native-reanimated'
import * as R from 'ramda'
import { Typography, Pressable } from 'lib/components'
import { useLabelAnimation } from './useLabelAnimation'
import { useErrorAnimation } from './useErrorAnimation'
import { AnimatedLabelProps, InputProps, StyledInputProps } from './types'

export const Input: React.FunctionComponent<InputProps> = ({
    value,
    label,
    fieldState,
    isPassword,
    onBlur,
    onChangeText
}) => {
    const inputRef = useRef<TextInput>(null)
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const hasError = Boolean(fieldState?.error)
    const isDirty = Boolean(fieldState?.isDirty)
    const animatedLabel = useLabelAnimation(isFocused, value)
    const animatedError = useErrorAnimation(hasError)

    const getInputBorderColor = R.cond([
        [R.always(hasError), R.always(theme.colors.error)],
        [R.always(isHovered), R.always(theme.colors.gray.strong)],
        [R.always(isDirty && !hasError), R.always(theme.colors.primary)],
        [R.always(isFocused), R.always(theme.colors.primary)],
        [R.T, R.always(theme.colors.border)]
    ])

    return (
        <Container>
            {label && (
                <AnimatedLabel
                    isFocused={isFocused}
                    value={value}
                    style={animatedLabel}
                >
                    <Pressable onPress={() => inputRef.current?.focus()}>
                        <Typography.Small>
                            {label}
                        </Typography.Small>
                    </Pressable>
                </AnimatedLabel>
            )}
            <Pressable
                onHoverIn={() => setIsHovered(true)}
                onHoverOut={() => setIsHovered(false)}
            >
                <StyledInput
                    ref={inputRef}
                    borderColor={getInputBorderColor()}
                    value={value}
                    onBlur={() => {
                        setIsFocused(false)
                        onBlur()
                    }}
                    secureTextEntry={isPassword}
                    onFocus={() => setIsFocused(true)}
                    onChangeText={onChangeText}
                    autoComplete="new-password" // disable autofill
                />
            </Pressable>
            <Animated.View style={animatedError}>
                <Typography.VerySmall color={theme.colors.error}>
                    {fieldState?.error?.message && fieldState.error.message}
                </Typography.VerySmall>
            </Animated.View>
        </Container>
    )
}

const AnimatedLabel = styled(Animated.View)<AnimatedLabelProps>`
    padding: 0 ${props => (props.isFocused || props.value) ? props.theme.gap / 2 : 0}px;
`

const Container = styled(View)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
`

const StyledInput = styled(TextInput)<StyledInputProps>`
    height: 40px;
    border-width: 1px;
    padding: 10px;
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    border-color: ${({ borderColor }) => borderColor};
`

import { useEffect } from 'react'
import { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useTheme } from 'lib/hooks'

const DURATION = 200
const LABEL_TOP = 11
const LABEL_LEFT = 12
const LABEL_TOP_MOVE = -8

export const useLabelAnimation = (isFocused: boolean, value: string) => {
    const theme = useTheme()
    const animatedLabelTop = useSharedValue(LABEL_TOP)
    const animatedLabel = useAnimatedStyle(() => ({
        top: animatedLabelTop.value,
        left: (!isFocused && !value)
            ? LABEL_LEFT
            : LABEL_LEFT - 4,
        position: 'absolute',
        zIndex: theme.zIndex.level999,
        backgroundColor: theme.colors.white
    }))

    useEffect(() => {
        if (isFocused || value) {
            animatedLabelTop.value = withTiming(LABEL_TOP_MOVE, { duration: DURATION })

            return
        }

        if (!isFocused && !value) {
            animatedLabelTop.value = withTiming(LABEL_TOP, { duration: DURATION })
        }
    }, [isFocused, value])

    return animatedLabel
}

import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { Login, Register } from 'features'
import { useTheme } from 'lib/hooks'
import { Screen } from './config'

const Stack = createStackNavigator()

export const Authorization: React.FunctionComponent = () => {
    const theme = useTheme()

    return (
        <Stack.Navigator
            screenOptions={{
                cardStyle: {
                    backgroundColor: theme.colors.white
                }
            }}
        >
            <Stack.Screen
                name={Screen.Login}
                component={Login}
            />
            <Stack.Screen
                name={Screen.Register}
                component={Register}
            />
        </Stack.Navigator>
    )
}

import { Text } from 'react-native'
import styled from 'styled-components/native'

type VerySmallProps = {
    color?: string
}

export const VerySmall = styled(Text)<VerySmallProps>`
    color: ${({ theme, color }) => color ?? theme.colors.gray.strong};
    font-size: 12px;
`

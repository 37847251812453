import { Text } from 'react-native'
import styled from 'styled-components/native'

type SmallProps = {
    color?: string
}

export const Small = styled(Text)<SmallProps>`
    color: ${({ theme, color }) => color ?? theme.colors.gray.strong};
    font-size: 14px;
    font-family: 'Roboto-Regular'
`

import { useColorScheme } from 'react-native'
import { Appearance } from 'lib/types'
import { darkTheme, lightTheme } from 'lib/styles'

export const useTheme = () => {
    const colorScehme = useColorScheme()
    const theme = colorScehme === Appearance.Dark
        ? darkTheme
        : lightTheme

    return theme
}

import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Home } from 'features'
import { useTheme } from 'lib/hooks'
import { Authorization } from './Authorization'
import { Screen } from './config'

const Tab = createBottomTabNavigator()

export const AppRouter: React.FunctionComponent = () => {
    const theme = useTheme()
    const isAuthorized = false

    if (!isAuthorized) {
        return (
            <Authorization />
        )
    }

    return (
        <Tab.Navigator
            sceneContainerStyle={{
                backgroundColor: theme.colors.white
            }}
        >
            <Tab.Screen
                name={Screen.Home}
                component={Home}
            />
        </Tab.Navigator>
    )
}
